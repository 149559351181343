import React, { useEffect, useState } from "react";
import { styled } from "styled-components";

//-------| Images/Icons |-------//
import Placeholder_image from "../../../assets/images/image_placeholder.gif";
import infoIcon from "../../../assets/images/Infoicon.svg";

//-------| Antd |-------//
import { Tooltip } from "antd";

//-------| Components Used |-------//
import ImageMagnifier from "../../general/ImageMagnifier";
import { formatDatev2, formatTime } from "../../../utils/HelperFunctions";

const EntryItems = ({
  itm,
  imageUrl,
  date,
  placement,
  entryImageLoading,
  handleEntryImageLoading,
  handleCheck,
  checkedItems,
}) => {
  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    handleCheck(imageUrl, date, isChecked);
  };

  const isDisabled =
    checkedItems?.length >= 2 &&
    !checkedItems?.some(
      (item) => item?.imageUrl === imageUrl && item?.date === date
    );

  return (
    <Entry>
      <CarCover>
        <Tooltip
          overlayInnerStyle={{ width: "570px" }}
          placement={placement}
          color={"black"}
          title={
            <a href={imageUrl} target="_blank">
              <ImageMagnifier src={imageUrl} width={"550px"} />
            </a>
          }
        >
          <CarImage href={imageUrl} target="_blank">
            <img
              src={entryImageLoading ? Placeholder_image : imageUrl}
              alt="Entry Img"
              onLoad={() => handleEntryImageLoading(false)}
            />
          </CarImage>
        </Tooltip>
      </CarCover>

      <EntryDetails>
        <Feilds>
          <FeildName>Date:</FeildName>

          {date !== null ? (
            <FeildData>{formatDatev2(date)}</FeildData>
          ) : (
            <NoDataDiv>
              <span>No info</span>
              <Tooltip
                overlayInnerStyle={{ width: "200px" }}
                placement="right"
                color={"black"}
                title={
                  "This record can' t be accepted since there is no date & time captured."
                }
              >
                <img src={infoIcon} style={{ cursor: "pointer" }} alt="info" />
              </Tooltip>
            </NoDataDiv>
          )}
        </Feilds>

        <Feilds>
          <FeildName>Time:</FeildName>
          {date !== null ? (
            <FeildData>{formatTime(date)}</FeildData>
          ) : (
            <NoDataDiv>
              <span>No info</span>
              <Tooltip
                overlayInnerStyle={{ width: "200px" }}
                placement="right"
                color={"black"}
                title={
                  "This record can' t be accepted since there is no date & time captured."
                }
              >
                <img src={infoIcon} style={{ cursor: "pointer" }} alt="info" />
              </Tooltip>
            </NoDataDiv>
          )}
        </Feilds>

        <FieldCheckbox
          type="checkbox"
          onChange={handleCheckboxChange}
          disabled={isDisabled}
        />
      </EntryDetails>
    </Entry>
  );
};

export default EntryItems;

const Entry = styled.div`
  flex: 1;
  display: flex;
  gap: 5px;
  padding: 0 10px;
  border-right: 1px solid #d0d1d2;

  &:last-child {
    border-right: none;
  }

  @media all and (min-width: 1550px) {
    gap: 20px;
  }
`;

const CarCover = styled.div``;

const CarImage = styled.a`
  display: block;
  flex: 1;
  min-width: 150px;
  height: auto;
  overflow: hidden;

  @media all and (max-width: 1550px) {
    max-width: 150px;
  }

  img {
    width: 240px;
    height: 180px;
    display: block;
    object-fit: cover;
    border-radius: 8px;
  }

  /*-------| Display Scale 125% |-------*/
  @media all and (min-resolution: 1.1dppx) and (max-resolution: 1.25dppx) {
    max-width: 240px;
  }

  /*-------| Display Scale 150% |-------*/
  @media all and (min-resolution: 1.26dppx) and (max-resolution: 1.5dppx) {
    max-width: 215px;

    img {
      width: 215px;
    }
  }
`;

const EntryDetails = styled.div`
  min-height: 175px;
  padding-top: 20px;
`;

const Feilds = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FeildName = styled.p`
  font-size: 16px;
  color: #6a6a6a;

  /*-------| Display Scale 150% |-------*/
  @media all and (min-resolution: 1.26dppx) and (max-resolution: 1.5dppx) {
    font-size: 12px;
  }
`;

const FeildData = styled.p`
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  font-family: "Roboto Mono";
  color: #000000;
  white-space: nowrap;

  /*-------| Display Scale 150% |-------*/
  @media all and (min-resolution: 1.26dppx) and (max-resolution: 1.5dppx) {
    font-size: 14px;
  }
`;

const NoDataDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #df4a4a;
`;

const FieldCheckbox = styled.input`
  width: 20px;
  height: 20px;
  margin: 30px auto 0 auto;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;
