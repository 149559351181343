import React, { useState, useEffect } from "react";
import { Modal, Input, Button, Form } from "antd";
import axiosConfig from "../../../axiosConfig";
import { Select } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";

const AddUserModal = ({
  visible,
  fetchData,
  setOpen,
  isEditing,
  editUserEmail,
  editUserRole,
  editUserId,
  setSuccessModal,
  setMessage,
  setpassinguserData,
}) => {
  const [userRole, setUserRole] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [showError, setshowError] = useState(false);
  const { userId } = useSelector((state) => state?.user?.userProfile);

  const handleNameChange = (e) => {
    setUserRole(e);
  };

  const handleEmailChange = (e) => {
    setUserEmail(e.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setshowError(e.target.value !== "" && !emailRegex.test(e.target.value));
  };

  const AddUserData = () => {
    setLoading(true);
    if (userEmail.length !== 0 && userRole.length !== 0) {
      setIsEmpty(false);
      const queryParams = {
        UserDetailsInput: JSON.stringify({
          emailId: userEmail,
          userRole: userRole,
          createdBy: userId,
        }),
      };
      axiosConfig
        .get(
          `AddNewUser?UserDetailsInput=`,

          {
            params: queryParams,
          }
        )
        .then((res) => {
          setLoading(false);
          fetchData();
          setOpen(false);
          setSuccessModal(true);
          setMessage("User created Successfully");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setIsEmpty(true);
    }
  };

  const editUser = () => {
    setpassinguserData(userRole);
    setLoading(true);
    if (userEmail.length !== 0 && userRole.length !== 0) {
      const queryParams = {
        EditUserDetailsInput: JSON.stringify({
          userId: editUserId,
          emailId: userEmail,
          userRole: userRole,
          modifiedBy: userId,
        }),
      };
      axiosConfig
        .get(
          `EditUser?EditUserDetailsInput=`,

          {
            params: queryParams,
          }
        )
        .then((res) => {
          setLoading(false);
          fetchData();
          setOpen(false);
          setSuccessModal(true);
          setMessage("User updated successfully");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setIsEmpty(true);
    }
  };

  const onCreate = () => {
    isEditing ? editUser() : AddUserData();
    setUserEmail("");
    setUserRole("");
  };

  const { Option } = Select;

  useEffect(() => {
    setUserEmail(editUserEmail);
    setUserRole(editUserRole);
  }, [editUserEmail, editUserRole]);

  const handleCancel = () => {
    setOpen(false);
    setUserEmail("");
    setUserRole("");
  };

  return (
    <Modal
      width={310}
      style={{ marginTop: "100px", height: "200px" }}
      visible={visible}
      //   title={isEditing ? "Edit User" : "Add User"}
      onCancel={handleCancel}
      footer={[
        <Button
          key="cancel"
          style={{ color: "#3D619B" }}
          onClick={handleCancel}
        >
          Cancel
        </Button>,

        <Button
          style={{ marginRight: "60px", backgroundColor: "#3D619B" }}
          key="add"
          type="primary"
          onClick={() => onCreate({ userRole, userEmail })}
          disabled={!userRole || !userEmail}
        >
          {isEditing ? "Edit" : "Add"}
        </Button>,
      ]}
    >
      <h3 style={{ color: "#3D619B" }}>
        {isEditing ? "Edit User" : "Add User"}
      </h3>

      <div
        style={{
          borderTop: " 1px solid #D0D1D2",
          marginBottom: "18px",
        }}
      />

      <div>
        <Form.Item
          help={
            <span style={{ fontSize: "10px" }}>
              {showError ? "Please enter a valid email address" : ""}
            </span>
          }
          validateStatus={showError ? "error" : ""}
        >
          <Input
            placeholder="User Email"
            value={userEmail}
            onChange={handleEmailChange}
            style={{
              borderColor: "#D0D1D2",
            }}
          />
        </Form.Item>
      </div>

      <div>
        <Select
          bordered={"#D0D1D2"}
          style={{
            width: "100%",
          }}
          placeholder="user Role"
          value={userRole ? userRole : null}
          onChange={(e) => handleNameChange(e)}
        >
          <Option value="analyst">Analyst</Option>
          <Option value="manager">Manager</Option>
        </Select>
      </div>
    </Modal>
  );
};

export default AddUserModal;
