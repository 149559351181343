import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

//-------| Components Used |-------//
import NoData from "../../general/NoData";
import { COLORS } from "../../../Constants";
import axiosConfig from "../../../axiosConfig";
import DataFetchIngLoader from "../../general/DataFetchIngLoader";
import { formatDatev2, formatTime } from "../../../utils/HelperFunctions";
import EntryItems from "./EntryItems";
import {
  setStarTraqData,
  updateDataProcessed,
} from "../../redux/reducers/UserReducer";

function StarTraqMain() {
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state?.user?.userProfile);
  const starTraqData = useSelector((state) => state?.user?.starTraqDetails);
  const { filmNumber, frame, site, offenceType, plate, offenceDate } =
    starTraqData || {};

  const [isLoading, setLoading] = useState(false);
  const [entryPlate, setEntryPlate] = useState(plate || "");
  const [rejectReason, setRejectReason] = useState(null);
  const [entryImageLoading, setEntryImageLoading] = useState(true);
  const [starTraqDetails, setStarTraqDetails] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [isValid, setIsValid] = useState(true);

  //-------| Reason Dropdown Options  |-------//
  const reasons = [
    { value: "", label: "Select a reason" },
    { value: "No ten minutes gap", label: "No ten minutes gap" },
    { value: "Bad image", label: "Bad image" },
    { value: "Duplicate Record", label: "Duplicate Record" },
    { value: "Client Request", label: "Client Request" },
  ];

  //-------| StarTraq Details Get Api |-------//
  const fetchStarTraqData = () => {
    setLoading(true);
    setEntryImageLoading(true);

    axiosConfig
      .get(`GetStarTraqForUI`)

      .then((res) => {
        const { status, data } = res;

        if (status === 200) {
          setStarTraqDetails(data);
          dispatch(setStarTraqData(data[0]));
        } else {
          setStarTraqDetails([]);
        }

        setLoading(false);
      })

      .catch((err) => {
        console.error("Error in StarTraq Details Get Api", err);
        setLoading(false);
      });
  };

  //-------| Accept/Reject Btn Get Api |-------//
  const starTraqApprovedPlateApi = (string) => {
    setLoading(true);

    const acceptanceString =
      string === "Accepted"
        ? "Submitted to DVLA"
        : string === "Rejected"
        ? "Rejected"
        : "";

    const myReason = string === "Rejected" ? rejectReason : "";

    const firstImage = starTraqData.imageUrl1;
    const firstDate = starTraqData.date1;
    const secondImage = starTraqData.imageUrl2;
    const secondDate = starTraqData.date2;

    const queryParams = {
      StarTraqDataInput: JSON.stringify({
        FilmNumber: filmNumber,
        Frame: frame,
        userId: userId,
        site: site,
        plate: entryPlate.toUpperCase(),
        CheckedimageUrl1: checkedItems[0]?.imageUrl || firstImage || null,
        CheckedDate1: checkedItems[0]?.date || firstDate || null,
        checkedimageUrl2: checkedItems[1]?.imageUrl || secondImage || null,
        checkedDate2: checkedItems[1]?.date || secondDate || null,
        offenceType: offenceType,
        acceptance: acceptanceString,
        offenceDate: offenceDate,
        rejectionReason: myReason,
      }),
    };

    axiosConfig
      .get(`InsertStarTraqApprovedPlate`, {
        params: queryParams,
      })

      .then((res) => {
        const { statusCode } = res.data;

        if (statusCode === 200) {
          fetchStarTraqData();
          fetchProcessedData();
          setCheckedItems([]);
          setEntryPlate(plate || "");
          setRejectReason(null);
        } else {
        }

        setLoading(false);
      })

      .catch((err) => {
        console.error("Error in StarTraq Approved Plate Api", err);
        setLoading(false);
      });
  };

  //-------| Processing & Processed count Get Api |-------//
  const fetchProcessedData = () => {
    axiosConfig
      .get(`GetProcessingProcessedStarTraqCount`)
      .then((res) => {
        const { statusCode, processedCount, processingCount } = res.data;
        if (statusCode === 200) {
          dispatch(
            updateDataProcessed({
              processed: processedCount,
              processing: processingCount,
            })
          );
        } else {
        }
      })
      .catch((err) => {
        console.error(
          "Error in StarTraq Processing & Processed count Api",
          err
        );
      });
  };

  useEffect(() => {
    fetchStarTraqData();
    fetchProcessedData();
    setCheckedItems([]);
    setEntryPlate(plate || "");
  }, []);

  useEffect(() => {
    setEntryPlate(plate || "");
  }, [plate]);

  const isNotNull = (value) => value !== null;

  const handleEntryImageLoading = (value) => {
    setEntryImageLoading(value);
  };

  const handlePlateChange = (e) => {
    const newPlate = e.target.value;

    const isValidPlate = newPlate;

    if (isValidPlate) {
      setIsValid(true);
      setEntryPlate(newPlate);
    } else {
      setIsValid(false);
      setEntryPlate(newPlate);
    }
  };

  const handlePlateCancel = () => {
    setEntryPlate(plate);
    setIsValid(true);
  };

  const handleCheck = (imageUrl, date, isChecked) => {
    if (isChecked) {
      setCheckedItems((prevItems) => [...prevItems, { imageUrl, date }]);
    } else {
      setCheckedItems((prevItems) =>
        prevItems.filter(
          (item) => !(item.imageUrl === imageUrl && item.date === date)
        )
      );
    }
  };

  const handleChangeReason = (e) => {
    const selectedValue = e.target.value;

    setRejectReason(selectedValue === "" ? null : selectedValue);
  };

  return (
    <Container>
      <MainDiv>
        <TopSection>
          <Title>StarTraq</Title>
        </TopSection>

        {isLoading ? (
          <Loader>
            <DataFetchIngLoader />
          </Loader>
        ) : (
          <DetailsDiv>
            {starTraqDetails.map((itm) => {
              return (
                <DetailsHead key={itm.plate}>
                  <EachHead>
                    <HeadKey>Site:</HeadKey>
                    <HeadValue>{itm.site}</HeadValue>
                  </EachHead>

                  <EachHead>
                    <HeadKey>Offense Date:</HeadKey>
                    <HeadValue>
                      {formatDatev2(itm.offenceDate)}{" "}
                      {formatTime(itm.offenceDate)}
                    </HeadValue>
                  </EachHead>

                  <EachHead>
                    <HeadKey>Offense Type:</HeadKey>

                    <HeadValue style={{ fontFamily: "Inter, sans-serif" }}>
                      {itm.offenceType}
                    </HeadValue>
                  </EachHead>
                </DetailsHead>
              );
            })}

            {starTraqDetails.map((itm, index) => {
              return (
                <>
                  {/*-------| Row-1 |-------*/}
                  {itm &&
                    (isNotNull(itm.date1) ||
                      isNotNull(itm.imageUrl1) ||
                      isNotNull(itm.date2) ||
                      isNotNull(itm.imageUrl2) ||
                      isNotNull(itm.date3) ||
                      isNotNull(itm.imageUrl3)) && (
                      <Cover>
                        <DetailsContainer key={itm.date1}>
                          {itm &&
                          (isNotNull(itm.date1) || isNotNull(itm.imageUrl1)) ? (
                            <EntryItems
                              itm={itm}
                              imageUrl={itm.imageUrl1}
                              date={itm.date1}
                              placement={"left"}
                              entryImageLoading={entryImageLoading}
                              handleEntryImageLoading={handleEntryImageLoading}
                              handleCheck={handleCheck}
                              checkedItems={checkedItems}
                            />
                          ) : (
                            <EmptyDiv />
                          )}

                          {itm &&
                          (isNotNull(itm.date2) || isNotNull(itm.imageUrl2)) ? (
                            <EntryItems
                              itm={itm}
                              imageUrl={itm.imageUrl2}
                              date={itm.date2}
                              placement={"right"}
                              entryImageLoading={entryImageLoading}
                              handleEntryImageLoading={handleEntryImageLoading}
                              handleCheck={handleCheck}
                              checkedItems={checkedItems}
                            />
                          ) : (
                            <EmptyDiv />
                          )}

                          {itm &&
                          (isNotNull(itm.date3) || isNotNull(itm.imageUrl3)) ? (
                            <EntryItems
                              itm={itm}
                              imageUrl={itm.imageUrl3}
                              date={itm.date3}
                              placement={"left"}
                              entryImageLoading={entryImageLoading}
                              handleEntryImageLoading={handleEntryImageLoading}
                              handleCheck={handleCheck}
                              checkedItems={checkedItems}
                            />
                          ) : (
                            <EmptyDiv />
                          )}
                        </DetailsContainer>
                      </Cover>
                    )}

                  {/*-------| Row-2 |-------*/}
                  {itm &&
                    (isNotNull(itm.date4) ||
                      isNotNull(itm.imageUrl4) ||
                      isNotNull(itm.date5) ||
                      isNotNull(itm.imageUrl5) ||
                      isNotNull(itm.date6) ||
                      isNotNull(itm.imageUrl6)) && (
                      <Cover>
                        <DetailsContainer key={index}>
                          {itm &&
                          (isNotNull(itm.date4) || isNotNull(itm.imageUrl4)) ? (
                            <EntryItems
                              itm={itm}
                              imageUrl={itm.imageUrl4}
                              date={itm.date4}
                              placement={"left"}
                              entryImageLoading={entryImageLoading}
                              handleEntryImageLoading={handleEntryImageLoading}
                              handleCheck={handleCheck}
                              checkedItems={checkedItems}
                            />
                          ) : (
                            <EmptyDiv />
                          )}

                          {itm &&
                          (isNotNull(itm.date5) || isNotNull(itm.imageUrl5)) ? (
                            <EntryItems
                              itm={itm}
                              imageUrl={itm.imageUrl5}
                              date={itm.date5}
                              placement={"right"}
                              entryImageLoading={entryImageLoading}
                              handleEntryImageLoading={handleEntryImageLoading}
                              handleCheck={handleCheck}
                              checkedItems={checkedItems}
                            />
                          ) : (
                            <EmptyDiv />
                          )}

                          {itm &&
                          (isNotNull(itm.date6) || isNotNull(itm.imageUrl6)) ? (
                            <EntryItems
                              itm={itm}
                              imageUrl={itm.imageUrl6}
                              date={itm.date6}
                              placement={"left"}
                              entryImageLoading={entryImageLoading}
                              handleEntryImageLoading={handleEntryImageLoading}
                              handleCheck={handleCheck}
                              checkedItems={checkedItems}
                            />
                          ) : (
                            <EmptyDiv />
                          )}
                        </DetailsContainer>
                      </Cover>
                    )}

                  {/*-------| Row-3 |-------*/}
                  {itm &&
                    (isNotNull(itm.date7) ||
                      isNotNull(itm.imageUrl7) ||
                      isNotNull(itm.date8) ||
                      isNotNull(itm.imageUrl8) ||
                      isNotNull(itm.date9) ||
                      isNotNull(itm.imageUrl9)) && (
                      <Cover>
                        <DetailsContainer key={index}>
                          {itm &&
                          (isNotNull(itm.date7) || isNotNull(itm.imageUrl7)) ? (
                            <EntryItems
                              itm={itm}
                              imageUrl={itm.imageUrl7}
                              date={itm.date7}
                              placement={"left"}
                              entryImageLoading={entryImageLoading}
                              handleEntryImageLoading={handleEntryImageLoading}
                              handleCheck={handleCheck}
                              checkedItems={checkedItems}
                            />
                          ) : (
                            <EmptyDiv />
                          )}

                          {itm &&
                          (isNotNull(itm.date8) || isNotNull(itm.imageUrl8)) ? (
                            <EntryItems
                              itm={itm}
                              imageUrl={itm.imageUrl8}
                              date={itm.date8}
                              placement={"right"}
                              entryImageLoading={entryImageLoading}
                              handleEntryImageLoading={handleEntryImageLoading}
                              handleCheck={handleCheck}
                              checkedItems={checkedItems}
                            />
                          ) : (
                            <EmptyDiv />
                          )}

                          {itm &&
                          (isNotNull(itm.date9) || isNotNull(itm.imageUrl9)) ? (
                            <EntryItems
                              itm={itm}
                              imageUrl={itm.imageUrl9}
                              date={itm.date9}
                              placement={"left"}
                              entryImageLoading={entryImageLoading}
                              handleEntryImageLoading={handleEntryImageLoading}
                              handleCheck={handleCheck}
                              checkedItems={checkedItems}
                            />
                          ) : (
                            <EmptyDiv />
                          )}
                        </DetailsContainer>
                      </Cover>
                    )}
                </>
              );
            })}
          </DetailsDiv>
        )}
      </MainDiv>

      <BottomSection>
        <LeftDiv>
          <CorrectThePlate isValid={isValid}>
            <span>Correct the plate</span>

            <input
              type="text"
              tabIndex="1"
              value={entryPlate}
              required
              onChange={handlePlateChange}
            />
            {!isValid && <p>*Number plate is required.</p>}

            <CancelBtn onClick={handlePlateCancel}>Cancel</CancelBtn>
          </CorrectThePlate>
        </LeftDiv>

        <RightDiv>
          <ReasonSelect
            placeholder="Select a reason"
            // defaultValue={""}
            value={rejectReason || ""}
            onChange={handleChangeReason}
          >
            {reasons.map((reason) => (
              <option key={reason.value} value={reason.value}>
                {reason.label}
              </option>
            ))}
          </ReasonSelect>

          {rejectReason === null || entryPlate === "" ? (
            <DisabledRejectBtn disabled>Reject</DisabledRejectBtn>
          ) : (
            <RejectBtn onClick={() => starTraqApprovedPlateApi("Rejected")}>
              Reject
            </RejectBtn>
          )}

          {checkedItems.length < 2 ||
          (checkedItems[0]?.date === null && checkedItems[1]?.date === null) ||
          rejectReason !== null ||
          entryPlate === "" ? (
            <DisabledAcceptBtn disabled>Accept</DisabledAcceptBtn>
          ) : (
            <AcceptBtn onClick={() => starTraqApprovedPlateApi("Accepted")}>
              Accept
            </AcceptBtn>
          )}
        </RightDiv>
      </BottomSection>
    </Container>
  );
}

export default StarTraqMain;

const Container = styled.div``;

const MainDiv = styled.div`
  width: 100%;
  height: calc(100vh - 155px);
  padding: 20px;
  overflow: scroll;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const DetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DetailsHead = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const Cover = styled.div`
  padding: 10px;
  border: 1px solid #d0d1d2;
  border-radius: 5px;
`;

const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
`;

const EmptyDiv = styled.div`
  flex: 1;
`;

const EachHead = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const HeadKey = styled.span`
  font-size: 18px;
  color: #6a6a6a;
`;

const HeadValue = styled.span`
  font-size: 18px;
  font-weight: 600;
  font-family: "Roboto Mono";
  color: #000000;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 270px);
`;

const Title = styled.h2`
  color: ${COLORS.THEME_COLOR};
  font-size: 20px;
  font-weight: 600;
  text-align: center;
`;

const BottomSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  padding: 10px 20px 20px 20px;
  background-color: #ffffff;
`;

const LeftDiv = styled.div``;

const CorrectThePlate = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  position: relative;

  span {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
  }

  input {
    width: 120px;
    height: 25px;
    margin-left: 10px;
    padding: 5px 10px;
    font-size: 18px;
    font-weight: 600;
    font-family: "Roboto Mono";
    border: 1px solid ${({ isValid }) => (isValid ? "#6a6a6a" : "#ff0000")};
    text-transform: uppercase;
  }

  p {
    font-size: "8px";
    color: #ff0000;
    font-weight: 400;
  }
`;

const BaseBtn = styled.button`
  width: 110px;
  height: 25px;
  font-size: 12px;
  font-weight: 500;
  color: #3d619b;
  border-radius: 5px;
  border: 1px solid #3d619b;
  background-color: #fff;
  cursor: pointer;
`;

const CancelBtn = styled(BaseBtn)``;

const RightDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ReasonSelect = styled.select`
  height: 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #1a73e8 !important;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
`;

const AcceptBtn = styled(BaseBtn)`
  color: #fff;
  background-color: #3d619b;
`;

const BaseDisableBtn = styled.button`
  width: 110px;
  height: 25px;
  background-color: #3d619b;
  color: #fff;
  border: 1px solid #3d619b;
  border-radius: 5px;
  opacity: 0.7;
  cursor: not-allowed;
`;

const DisabledAcceptBtn = styled(BaseDisableBtn)``;

const DisabledRejectBtn = styled(BaseDisableBtn)`
  background: #fff;
  color: #3d619b;
`;

const RejectBtn = styled(BaseBtn)``;
